import * as React from 'react';
import { FunctionComponent } from 'react';
import { Typography, styleVariables, Grid } from '@searchmetrics/core-ui-kit';
import styled from 'styled-components';
import Card from 'components/Card';
import VerifyEmailIcon from 'assets/icons/icon_email-verification.svg';
import StyledThemedButton from 'styles/StyledThemedButton';

const Root = styled.div`
  margin-top: 46px;
`;

const CardContent = styled(Grid)`
  margin-top: ${styleVariables.spacings['space.11']};
  margin-bottom: ${styleVariables.spacings['space.11']};
`;

const StyledTypography = styled(Typography)`
  text-align: center;
  margin-bottom: ${styleVariables.spacings['space.32']};
`;

const CallToActionTypography = styled(Typography)`
  width: 100%;
  text-align: center;
  margin-top: 10px;
`;

const VerifyEmail: FunctionComponent = () => (
  <Root>
    <StyledTypography fontSize="$font_size--40" fontWeight="$font_weight--bold">
      Please verify your email address
    </StyledTypography>
    <Card withPadding>
      <CardContent alignItems="center" justifyItems="center" columns={1} gap={{ row: 32 }}>
        <Grid.Item>
          <img src={VerifyEmailIcon} alt="" width={64} height={64} />
        </Grid.Item>
        <Grid.Item>
          <Typography fontWeight="$font_weight--bold" fontSize="$font_size--16">
            We sent you an email with a link to verify your email address.
          </Typography>
          <CallToActionTypography fontSize="$font_size--16" color={'$purple_moderate--400'}>
            Already did it? Click to refresh.
          </CallToActionTypography>
        </Grid.Item>
        <Grid.Item>
          <StyledThemedButton fontSize="$font_size--16" onClick={() => window.location.reload()}>
            Refresh page
          </StyledThemedButton>
        </Grid.Item>
      </CardContent>
    </Card>
  </Root>
);

export default VerifyEmail;
