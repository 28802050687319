import { compose, differenceWith, find, is, map, prop, propEq, sortBy, uniq, whereEq } from 'ramda';
import engines from 'sm-search-engines-list/research-search-engines-list';

const translations = {
  CountryDE: 'Germany',
  CountryGB: 'United Kingdom',
  CountryFR: 'France',
  CountryIT: 'Italy',
  CountryES: 'Spain',
  CountryRU: 'Russian Federation',
  CountryAT: 'Austria',
  CountryUS: 'USA',
  CountryDK: 'Denmark',
  CountryIE: 'Ireland',
  CountryMX: 'Mexico',
  CountryNL: 'Netherlands',
  CountrySE: 'Sweden',
  CountryCH: 'Switzerland',
  CountryPL: 'Poland',
  CountryBR: 'Brazil',
  CountryTR: 'Turkey',
  CountryFI: 'Finland',
  CountryNO: 'Norway',
  CountryCA: 'Canada',
  CountryAU: 'Australia',
  CountryCN: 'China',
  CountryHK: 'Hong Kong',
  CountryJP: 'Japan',
  CountryIN: 'India',
  CountryBE: 'Belgium',
  CountryAR: 'Argentina',
  CountryZA: 'South Africa',
  CountryCO: 'Colombia',
  CountrySG: 'Singapore',
};

type SearchEngine = {
  key: string;
  name: string;
  location: string | null;
  device: string;
  os: null;
  se_desktop: number;
  se_mobile: number;
  country_code: string;
  language_code: string;
  is_default: boolean;
  is_local: boolean;
  is_research: boolean;
  is_visible: boolean;
};

const sortByPreferredCountries = (data: Array<SearchEngine>) => {
  const first = map((el) => find(whereEq({ country_code: el }), data), [
    'US',
    'GB',
    'DE',
    'AT',
    'CH',
    'FR',
  ]);

  return first.concat(
    compose(differenceWith((a, b) => a.country_code === b.country_code, data))(first)
  );
};

export default compose(
  sortByPreferredCountries,
  sortBy(prop('translation')),
  uniq,
  map((el) => ({
    ...el,
    translation: translations[`Country${el.country_code}`],
  }))
)(engines);

export const getSearchEngineById = (seId: string): SearchEngine =>
  compose(
    (engine) => (is(Object, engine) ? engine : {}),
    find(propEq('se_desktop', parseInt(seId, 10)))
  )(engines);
