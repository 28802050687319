// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck

import React, { FunctionComponent, ReactElement, useEffect, lazy, Suspense } from 'react';
import '@searchmetrics/core-ui-kit/main.css';
import {
  Flex,
  Typography,
  Loader,
  ThemeContext,
  mergeWithDefaultTheme,
  styleVariables,
} from '@searchmetrics/core-ui-kit';
import { Route, Switch, useLocation } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';
import styled from 'styled-components';

import AppBar from 'components/AppBar/AppBar';
import AppFooter from 'components/AppFooter';

import ProtectedRoute from 'routes/ProtectedRoute';
import {
  COMING_SOON,
  HOME,
  LOGIN,
  OPTIMIZE_CONTENT,
  RANKING_OPPORTUNITIES,
  BACKLINK_OPPORTUNITIES,
  CORE_WEB_VITALS,
  HISTORIC_WINNERS_LOSERS,
  PRIVACY_POLICY,
  TERMS_AND_CONDITIONS,
} from 'routes';

import 'App.scss';
import { trackPageView } from 'helpers/tracking';
import device, { APP_MAX_WIDTH } from 'helpers/device';
import { lightGreen } from 'styles/colors';

const loaderStyle = { marginTop: '300px' };
const theme = {
  palette: {
    brand: {
      main: '$green_moderate_cyan--900', // default & hover
      light: '#faeeed', // active
      lighter: lightGreen, // disabled
      contrast: '#fff',
    },
  },
};

const HEADER_FOOTER_HEIGHT = 75;

const Content = styled.div`
  padding-left: 10%;
  padding-right: 10%;
  width: 100%;
  @media (${device.laptopL}) {
    max-width: ${APP_MAX_WIDTH};
  }
`;

const Header = styled.header`
  z-index: ${styleVariables.zIndex['zIndex.level.10']};
  background-color: white;
  height: ${HEADER_FOOTER_HEIGHT}px;
  box-shadow: 0 2px 20px 0 rgba(207, 209, 221, 0.5);
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  display: flex;
  justify-content: center;
  box-sizing: border-box;
  flex-shrink: 0;
`;

const HeaderContent = styled(Content)`
  display: flex;
  justify-content: center;
  flex-direction: column;
  padding-left: 10%;
  padding-right: 10%;
  width: 100%;
  @media (${device.laptopL}) {
    max-width: ${APP_MAX_WIDTH};
  }
`;

const Body = styled.div`
  display: flex;
  justify-content: center;
  margin-top: ${HEADER_FOOTER_HEIGHT}px;
  min-height: calc(100vh - ${2 * HEADER_FOOTER_HEIGHT}px);
`;

const Page = styled.div`
  width: inherit;
  height: 100%;
`;

const Footer = styled.footer`
  background-color: white;
  height: ${HEADER_FOOTER_HEIGHT}px;
  box-shadow: 0 2px 20px 0 rgba(207, 209, 221, 0.5);
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  flex-shrink: 0;
  padding-left: 10%;
  padding-right: 10%;
  width: 100%;
`;

const HomePage = lazy(() => import('./pages/HomePage'));
const LoginPage = lazy(() => import('./pages/LoginPage'));
const ComingSoonPage = lazy(() => import('./pages/ComingSoonPage'));
const PrivacyPolicyPage = lazy(() => import('./pages/PrivacyPolicyPage'));
const TermsAndConditionsPage = lazy(() => import('./pages/TermsAndConditionsPage'));
const OptimizeContentPage = lazy(() => import('./pages/OptimizeContentPage'));
const OptimizeContentPreviewPage = lazy(() => import('./pages/OptimizeContentPreviewPage'));
const RankingOpportunitiesPage = lazy(() => import('./pages/RankingOpportunitiesPage'));
const RankingOpportunitiesPreviewPage = lazy(
  () => import('./pages/RankingOpportunitiesPreviewPage')
);
const BacklinkOpportunitiesPage = lazy(() => import('./pages/BacklinkOpportunitiesPage'));
const BacklinkOpportunitiesPreviewPage = lazy(
  () => import('./pages/BacklinkOpportunitiesPreviewPage')
);
const CoreWebVitalsPage = lazy(() => import('./pages/CoreWebVitalsPage'));
const CoreWebVitalsPreviewPage = lazy(() => import('./pages/CoreWebVitalsPreviewPage'));
const HistoricWinnersLosersPage = lazy(() => import('./pages/HistoricWinnersLosersPage'));
const HistoricWinnersLosersPreviewPage = lazy(
  () => import('./pages/HistoricWinnersLosersPreviewPage')
);
const RouteNotFoundPage = lazy(() => import('./pages/RouteNotFoundPage'));

const LoadingComponent = () => (
  <Flex.Item style={loaderStyle}>
    <Loader centered />
  </Flex.Item>
);

const App: FunctionComponent = (): ReactElement => {
  const { isLoading, error } = useAuth0();
  const location = useLocation();
  useEffect(() => {
    trackPageView(location.pathname);
  }, [location]);

  return (
    <ThemeContext.Provider value={{ theme: mergeWithDefaultTheme(theme) }}>
      <Header>
        <HeaderContent>
          <AppBar />
        </HeaderContent>
      </Header>
      <Body>
        <Content>
          <Page alignItems="center" justifyContent="center">
            {isLoading && <LoadingComponent />}
            {error && <Typography>{error.message}</Typography>}
            {!isLoading && !error && (
              <Suspense fallback={<LoadingComponent />}>
                <Switch>
                  <Route exact path={HOME} component={HomePage} />
                  <Route exact path={LOGIN} component={LoginPage} />
                  <Route exact path={COMING_SOON} component={ComingSoonPage} />
                  <Route exact path={PRIVACY_POLICY} component={PrivacyPolicyPage} />
                  <Route exact path={TERMS_AND_CONDITIONS} component={TermsAndConditionsPage} />
                  <ProtectedRoute
                    path={OPTIMIZE_CONTENT}
                    component={OptimizeContentPage}
                    previewComponent={OptimizeContentPreviewPage}
                  />
                  <ProtectedRoute
                    path={RANKING_OPPORTUNITIES}
                    component={RankingOpportunitiesPage}
                    previewComponent={RankingOpportunitiesPreviewPage}
                  />
                  <ProtectedRoute
                    path={BACKLINK_OPPORTUNITIES}
                    component={BacklinkOpportunitiesPage}
                    previewComponent={BacklinkOpportunitiesPreviewPage}
                  />
                  <ProtectedRoute
                    path={CORE_WEB_VITALS}
                    component={CoreWebVitalsPage}
                    previewComponent={CoreWebVitalsPreviewPage}
                  />
                  <ProtectedRoute
                    path={HISTORIC_WINNERS_LOSERS}
                    component={HistoricWinnersLosersPage}
                    previewComponent={HistoricWinnersLosersPreviewPage}
                  />
                  <Route component={RouteNotFoundPage} />
                </Switch>
              </Suspense>
            )}
          </Page>
        </Content>
      </Body>
      <Footer>
        <AppFooter />
      </Footer>
    </ThemeContext.Provider>
  );
};

export default App;
