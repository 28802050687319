import { Flex, styleVariables } from '@searchmetrics/core-ui-kit';
import Modal from 'components/Modal';
import Workflow from 'helpers/workflows';
import workflowDetails from 'helpers/workflows/workflowDetails';
import React, { FunctionComponent } from 'react';
import styled from 'styled-components';
import StandardTypography from 'styles/StandardTypography';
import Ribbon, { RIBBON_SIZE } from 'styles/Ribbon';

const Root = styled.div`
  position: relative;
  margin: calc(-${styleVariables.spacings['space.24']} - 1px);
  padding: ${styleVariables.spacings['space.24']};
`;
const StyledModalHeadline = styled(Modal.Headline)`
  padding-bottom: ${styleVariables.spacings['space.50']};
`;

const ContentFlex = styled(Flex)`
  margin-right: ${styleVariables.spacings['space.50']};
`;

const Icon = styled.img`
  height: 62px;
  margin-right: ${styleVariables.spacings['space.24']};
`;

type Props = {
  onHide: () => void;
  workflow: Workflow;
};

const ComingSoonModal: FunctionComponent<Props> = ({ onHide, workflow }) => {
  const { name, headline, iconComponent } = workflowDetails[workflow];
  return (
    <Modal onHide={onHide} testId="coming-soon-modal">
      <Modal.Content>
        <Root>
          <Ribbon size={RIBBON_SIZE.BIG} />
          <StyledModalHeadline value={name} />
          <ContentFlex alignItems="center">
            <Flex.Item>
              <Icon src={iconComponent} alt="" />
            </Flex.Item>
            <Flex.Item>
              <StandardTypography fontSize="$font_size--20" color="$black">
                {headline}
              </StandardTypography>
            </Flex.Item>
          </ContentFlex>
        </Root>
      </Modal.Content>
    </Modal>
  );
};

export default ComingSoonModal;
