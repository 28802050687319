import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter as Router } from 'react-router-dom';
import Auth0ProviderWithHistory from './auth/Auth0ProviderWithHistory';
import { renderToDOM as renderSmDiscoverRemoteToDOM } from '@searchmetrics/sm-discover-remote';

import { checkShouldUseRemoteModule, getEnvironment } from './setup';

const shouldUseRemoteModule = checkShouldUseRemoteModule();
const rootNode = document.querySelector('#root');

if (shouldUseRemoteModule) {
  const environment = getEnvironment();

  renderSmDiscoverRemoteToDOM(rootNode, {
    environment
  });
} else {
  ReactDOM.render(
    <React.StrictMode>
      <Router basename={process.env.PUBLIC_URL}>
        <Auth0ProviderWithHistory>
          <App />
        </Auth0ProviderWithHistory>
      </Router>
    </React.StrictMode>,
    rootNode
  );
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
