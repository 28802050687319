enum Workflow {
  BACKLINK_OPPORTUNITIES = 'BacklinkOpportunities',
  CORE_WEB_VITALS = 'CoreWebVitals',
  FEATURED_SNIPPET_OPPORTUNITIES = 'FeaturedSnippetOpportunities',
  HISTORIC_WINNERS_LOSERS = 'HistoricWinnersLosers',
  OPTIMIZE_CONTENT = 'OptimizeContent',
  RANKING_OPPORTUNITIES = 'RankingOpportunities',
  STRUCTURED_DATA_CHECK = 'StructuredDataCheck',
}

export default Workflow;
