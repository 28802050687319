import React, { FunctionComponent, ReactNode } from 'react';
import { Button, Flex, Icon, styleVariables, Typography } from '@searchmetrics/core-ui-kit';
import styled from 'styled-components';

const OverlayBox = styled.div`
  position: fixed;
  display: block;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  transition: background 225ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  z-index: ${styleVariables.zIndex['zIndex.level.10']};
  overflow-y: auto;
`;

const OverlayContentFlex = styled(Flex)`
  width: 100%;
  height: 100%;
  cursor: default;
`;

const CloseButton = styled(Button)`
  height: 48px;
  width: 48px;
  color: white;
  border-color: ${styleVariables.colors['green.moderate.cyan.900']};
  margin-left: -${styleVariables.spacings['space.24']};
  margin-top: -${styleVariables.spacings['space.24']};
  right: 0;
  z-index: ${styleVariables.zIndex['zIndex.level.01']};
`;

const CloseIcon = styled(Icon)`
  margin-left: -${styleVariables.spacings['space.10']};
  margin-top: -${styleVariables.spacings['space.04']};
`;

const RootFlex = styled(Flex)`
  width: 800px;
  max-height: 80vh;
`;

const ContentFlexItem = styled(Flex.Item)<{ withoutPadding?: boolean }>`
  background-color: white;
  padding: ${({ withoutPadding }) => (withoutPadding ? 0 : styleVariables.spacings['space.50'])};
`;

const LeftContentFlexItem = styled(Flex.Item)`
  background-color: white;
  padding-top: 49px;
  padding-right: 28px;
  padding-left: 46px;
`;

const HeaderTypography = styled(Typography)`
  padding-bottom: ${styleVariables.spacings['space.24']};
  padding-right: 40px;
`;

type ModalHeadlineProps = { value: string; className?: string };
const ModalHeadline: FunctionComponent<ModalHeadlineProps> = ({ value, className }) => (
  <HeaderTypography fontSize="$font_size--32" fontWeight="$font_weight--bold" className={className}>
    {value}
  </HeaderTypography>
);

type ModalContentProps = ReactNode & { withoutPadding?: boolean };

const ModalContent: FunctionComponent<ModalContentProps> = ({ children, withoutPadding }) => (
  <ContentFlexItem withoutPadding={withoutPadding}>{children}</ContentFlexItem>
);

const ModalLeftContent: FunctionComponent<ReactNode> = ({ children }) => (
  <LeftContentFlexItem>{children}</LeftContentFlexItem>
);

const ModalRightContent: FunctionComponent<ReactNode> = ({ children }) => (
  <Flex.Item>{children}</Flex.Item>
);

type ModalProps = {
  onHide: () => void;
  direction?: 'column' | 'row';
  className?: string;
  testId?: string;
};

type ModalStaticProps = {
  Headline: FunctionComponent<ModalHeadlineProps>;
  Content: FunctionComponent<ModalContentProps>;
  LeftContent: FunctionComponent<ReactNode>;
  RightContent: FunctionComponent<ReactNode>;
};

const Modal: FunctionComponent<ModalProps> & ModalStaticProps = ({
  children,
  onHide,
  direction = 'column',
  className,
  testId,
}) => (
  <OverlayBox onClick={onHide} data-testid={testId}>
    <OverlayContentFlex justifyContent="center" alignItems="center">
      <Flex.Item>
        <Flex>
          <Flex.Item>
            <div
              onClick={(_e) => {
                _e.stopPropagation();
              }}
            >
              <RootFlex direction={direction} className={className}>
                {children}
              </RootFlex>
            </div>
          </Flex.Item>
          <Flex.Item>
            <CloseButton round="medium" onClick={onHide}>
              <CloseIcon
                fontSize="$font_size--40"
                icon="$icon--close"
                color="$green_moderate_cyan--900"
                hoverColor="$green_moderate_cyan--900"
              />
            </CloseButton>
          </Flex.Item>
        </Flex>
      </Flex.Item>
    </OverlayContentFlex>
  </OverlayBox>
);

Modal.Headline = ModalHeadline;
Modal.Content = ModalContent;
Modal.LeftContent = ModalLeftContent;
Modal.RightContent = ModalRightContent;

export default Modal;
