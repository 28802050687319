// eslint-disable-next-line no-console
const defaultErrorHandler = (e) => console.error(e);

type Props = {
  // TODO define a proper type that works with URLSearchParams
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  params: Record<string, any>;
  onError?: (e) => void;
};

const postFeedback = async ({
  params,
  onError = defaultErrorHandler,
}: // TODO define a proper return type
// eslint-disable-next-line @typescript-eslint/no-explicit-any
Props): Promise<any> => {
  try {
    const url = new URL(`${process.env.REACT_APP_API_BASE_URL}/feedback/`);
    const urlParams = new URLSearchParams(params);
    urlParams.append('version', process.env.REACT_APP_VERSION || '');
    url.search = urlParams.toString();

    const response = await fetch(url.toString(), {
      method: 'POST',
      headers: {
        'content-type': 'application/json',
      },
    });
    const json = await response.json();

    return !!json;
  } catch (e) {
    onError(e);
    return null;
  }
};

export default postFeedback;
