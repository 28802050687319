import { styleVariables } from '@searchmetrics/core-ui-kit';
import React, { FunctionComponent } from 'react';
import styled from 'styled-components';
import { darkBlue } from 'styles/colors';

export enum RIBBON_SIZE {
  SMALL = 'small',
  BIG = 'big',
}

const RibbonRoot = styled.div<{ size: RIBBON_SIZE }>`
  width: ${({ size }) => (size === RIBBON_SIZE.SMALL ? 120 : 180)}px;
  height: ${({ size }) => (size === RIBBON_SIZE.SMALL ? 120 : 180)}px;
  overflow: hidden;
  position: absolute;
`;

const RibbonTopRight = styled(RibbonRoot)`
  top: calc(-${styleVariables.spacings['space.24']} - 1px);
  right: calc(-${styleVariables.spacings['space.24']} - 1px);
  :before,
  :after {
    border-top-color: transparent;
    border-right-color: transparent;
  }
  :before {
    top: 0;
    left: 0;
  }
  :after {
    bottom: 0;
    right: 0;
  }
`;

const RibbonSpan = styled.span<{ size: RIBBON_SIZE }>`
  position: absolute;
  display: block;
  width: ${({ size }) => (size === RIBBON_SIZE.SMALL ? 195 : 260)}px;
  background-color: ${darkBlue};
  color: #fff;
  text-transform: uppercase;
  text-align: center;
  font-size: ${({ size }) =>
    size === RIBBON_SIZE.SMALL
      ? styleVariables.typography['font.size.13']
      : styleVariables.typography['font.size.20']};
  font-weight: ${({ size }) =>
    size === RIBBON_SIZE.SMALL
      ? styleVariables.typography['font.weight.bold']
      : styleVariables.typography['font.weight.normal']};
  font-stretch: normal;
  font-style: normal;
  line-height: 2.82;
  letter-spacing: 0.85px;
`;

const RibbonTopRightSpan = styled(RibbonSpan)`
  left: -${({ size }) => (size === RIBBON_SIZE.SMALL ? 25 : 20)}px;
  top: ${({ size }) => (size === RIBBON_SIZE.SMALL ? 30 : 40)}px;
  transform: rotate(45deg);
`;

const Ribbon: FunctionComponent<{ text?: string; size?: RIBBON_SIZE }> = ({
  text = 'Coming soon',
  size = RIBBON_SIZE.SMALL,
}) => (
  <RibbonTopRight size={size} data-testid="top-right-ribbon">
    <RibbonTopRightSpan size={size}>{text}</RibbonTopRightSpan>
  </RibbonTopRight>
);

export default Ribbon;
