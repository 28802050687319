import React from 'react';
import { Route, RouteProps } from 'react-router-dom';
import { useAuth0, withAuthenticationRequired } from '@auth0/auth0-react';
import VerifyEmail from 'components/VerifyEmail/VerifyEmail';

interface Props extends RouteProps {
  component: React.FunctionComponent;
  previewComponent?: React.FunctionComponent;
}

const ProtectedRoute: React.FunctionComponent<Props> = ({
  component,
  previewComponent,
  ...args
}) => {
  const { user, isAuthenticated } = useAuth0();
  if (user && !user.email_verified) {
    return <VerifyEmail />;
  }

  if (!isAuthenticated && previewComponent) {
    return <Route component={previewComponent} {...args} />;
  }

  return <Route component={withAuthenticationRequired(component, {})} {...args} />;
};

export default ProtectedRoute;
