// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck

import React, { FunctionComponent } from 'react';
import { useHistory } from 'react-router-dom';
import { Auth0Provider } from '@auth0/auth0-react';
import { getWindowUrl } from 'helpers/urlHelper';

type Props = {
  children?: React.ReactNode;
};

/*
  Auth0ProviderWithHistory is an implementation of Auth0Provider (in other words React.Context.Provider) by Auth0,
  which is used on the very top level of an application and lets it's children to
  access all Auth0 related information (isAuthenticated, loginWithRedirect, logout, etc.)

  Additionally Auth0ProviderWithHistory adds onRedirectCallback to the Auth0Provider based on the react-router-dom/history
  This allows us to always redirect the users back to the URL they wanted to visit in our app,
  even when they need to sign up or log in first (and get redirected to auth0 to do so)
 */
const Auth0ProviderWithHistory: FunctionComponent<Props> = ({ children }) => {
  const domain = process.env.REACT_APP_AUTH0_DOMAIN;
  const clientId = process.env.REACT_APP_AUTH0_CLIENT_ID;

  const history = useHistory();

  const onRedirectCallback = (appState) => {
    history.push(appState?.returnTo || getWindowUrl());
  };

  return (
    <Auth0Provider
      domain={domain}
      clientId={clientId}
      redirectUri={window.location.origin}
      onRedirectCallback={onRedirectCallback}
    >
      {children}
    </Auth0Provider>
  );
};

export default Auth0ProviderWithHistory;
