import React, { CSSProperties, FunctionComponent } from 'react';
import { Card as CoreCard, styleVariables, Typography } from '@searchmetrics/core-ui-kit';
import styled, { css } from 'styled-components';

const Root = styled.div<{ blurCard?: boolean; showIndex?: boolean }>`
  ${(props) =>
    props.blurCard &&
    css`
      filter: blur(5px) grayscale(50%);
    `};

  ${(props) =>
    props.showIndex &&
    css`
      /* Increment the value of searchResultCard counter by 1 */
      counter-increment: searchResultCard;
    `};
`;

const Index = styled.div`
  position: absolute;
  width: 90px;
  margin-left: -110px;
  text-align: right;
  margin-top:  ${styleVariables.spacings['space.24']};
  margin-right: 20px;
  color: #d2d4e0;
  div:before {
    content: counter(searchResultCard);
    }
})`;

const StyledCoreCard = styled(CoreCard)`
  margin-bottom: ${styleVariables.spacings['space.24']};
  border-radius: ${styleVariables.spacings['space.11']};
  box-shadow: 0 2px 20px 0 rgba(207, 209, 221, 0.5), inset 0 1px 0 0 #ffffff;
  background-color: transparent;
  width: 100%;
`;

const CardContent = styled.div<{ withPadding?: boolean }>`
  width: inherit;
  margin: ${styleVariables.spacings['space.11']};
  background-color: ${styleVariables.colors.white};
  box-shadow: 0 2px 10px 0 rgba(207, 209, 221, 0.3);
  ${(props) =>
    props.withPadding &&
    css`
      padding: ${styleVariables.spacings['space.24']};
    `}
`;

type Props = {
  showIndex?: boolean;
  blurCard?: boolean;
  style?: CSSProperties;
  withPadding?: boolean;
};
const Card: FunctionComponent<Props> = ({ children, showIndex, blurCard, withPadding }) => (
  <Root blurCard={blurCard} data-testid="generic-card" showIndex={showIndex}>
    {showIndex && (
      <Index data-testid="card-index">
        <Typography fontSize={'$font_size--72'} fontWeight={'$font_weight--bold'} />
      </Index>
    )}

    <StyledCoreCard>
      <CardContent data-testid="generic-card-content" withPadding={withPadding}>
        {children}
      </CardContent>
    </StyledCoreCard>
  </Root>
);

export default Card;
