// This file contains temporary solution for text copies until project is integrated with phrase.com

import * as routes from 'routes';
import BacklinkOpportunitiesIcon from 'assets/icons/icon-backlink-opportunities.svg';
import CoreWebVitalsIcon from 'assets/icons/icon-core-web-vitals.svg';
import FeaturedSnippetOpportunitiesIcon from 'assets/icons/icon_featured-snippet-keywords.svg';
import OptimizeContentIcon from 'assets/icons/icon_optimize-content.svg';
import RankingOpportunitiesIcon from 'assets/icons/icon_ranking-opportunities.svg';
import StructuredDataCheckIcon from 'assets/icons/icon_canonical-check.svg';
import HistoricWinnerLoserIcon from 'assets/icons/icon_winner-loser.svg';
import Workflow from 'helpers/workflows';

export interface DetailsType {
  name: string;
  headline: string;
  description?: string;
  iconComponent: string;
  route: string;
}

type WorkflowDetailsType = Record<Workflow, DetailsType>;
const workflowDetails: WorkflowDetailsType = {
  [Workflow.BACKLINK_OPPORTUNITIES]: {
    name: 'Backlink Opportunities',
    headline:
      'This Action will help you to find new Backlink Opportunities based on your competition.',
    description:
      "Try to acquire a backlink from these Referring Domains so you can stay competitive and increase your website's authority. Backlinks are an important ranking factor, so getting links referring to your website, might help you improve your rankings.",
    iconComponent: BacklinkOpportunitiesIcon,
    route: routes.BACKLINK_OPPORTUNITIES,
  },

  [Workflow.CORE_WEB_VITALS]: {
    name: 'Core Web Vitals',
    headline:
      "This Action will help you to analyze and optimize your website for Google's Core Web Vitals.",
    description:
      'You can also compare yourself against the competition to find gaps and prioritize areas to improve.',
    iconComponent: CoreWebVitalsIcon,
    route: routes.CORE_WEB_VITALS,
  },

  [Workflow.FEATURED_SNIPPET_OPPORTUNITIES]: {
    name: 'Featured Snippet Opportunities',
    headline: 'This Action will help you to find the best keywords to rank with Featured Snippets.',
    description: '',
    iconComponent: FeaturedSnippetOpportunitiesIcon,
    route: routes.COMING_SOON,
  },

  [Workflow.HISTORIC_WINNERS_LOSERS]: {
    name: 'Historic Winners & Losers',
    headline:
      'This action will help you to understand how your keywords positions on search engines evolved over time.',
    description:
      'We will analyze a set of keywords that over time gain position upgrade (winners) or lost position (losers) in search engine result pages. You can easily spot which topics have lost traffic to this domain or gain more traffic to it. You can also get hints on what to do next with this set of keywords.',
    iconComponent: HistoricWinnerLoserIcon,
    route: routes.HISTORIC_WINNERS_LOSERS,
  },

  [Workflow.OPTIMIZE_CONTENT]: {
    name: 'Optimize Existing Content',
    headline: 'This Action will help you to get more potential out of your existing content.',
    description:
      'We will analyze your pages with the highest potential and give keyword recommendations that you can use to re-optimize your Content and gain more traffic.',
    iconComponent: OptimizeContentIcon,
    route: routes.COMING_SOON,
  },

  [Workflow.RANKING_OPPORTUNITIES]: {
    name: 'Ranking Opportunities',
    headline: 'This Action will help you to find new Topics & Keywords based on your competition.',
    description:
      'We are analyzing your most important competitors and identify powerful keywords where your competitor is ranking for, but you are not. This helps you to identify new ranking opportunities.',
    iconComponent: RankingOpportunitiesIcon,
    route: routes.RANKING_OPPORTUNITIES,
  },

  [Workflow.STRUCTURED_DATA_CHECK]: {
    name: 'Structured Data Check',
    headline:
      'This Action will help you to check if you are using the correct Structured Data on your pages.',
    description: '',
    iconComponent: StructuredDataCheckIcon,
    route: routes.COMING_SOON,
  },
};

export default workflowDetails;
