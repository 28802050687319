import * as React from 'react';
import { FunctionComponent, useCallback } from 'react';
import styled from 'styled-components';
import { Button, Flex, styleVariables } from '@searchmetrics/core-ui-kit';
import { useAuth0 } from '@auth0/auth0-react';
import { useHistory } from 'react-router-dom';
import { HOME } from 'routes';
import NavigationMenu from 'components/NavigationMenu';
import { getWindowUrl } from 'helpers/urlHelper';
import StyledThemedButton from 'styles/StyledThemedButton';
import ImgWithFallback from 'components/ImgWithFallback';
import logoWebp from 'assets/images/logo.webp';
import logoPng from 'assets/images/logo.png';

const Root = styled(Flex)`
  padding-left: ${styleVariables.spacings['space.24']};
  padding-right: ${styleVariables.spacings['space.24']};
`;

const NavigationMenuButton = styled(Flex.Item)`
  margin-left: 90px;
`;

const LoginButton = styled(StyledThemedButton)`
  padding-top: ${styleVariables.spacings['space.08']};
  padding-bottom: ${styleVariables.spacings['space.08']};
  margin-top: ${styleVariables.spacings['space.04']};
`;

const AppBar: FunctionComponent = () => {
  const { isAuthenticated, loginWithRedirect, logout } = useAuth0();
  const history = useHistory();
  const login = useCallback(
    () =>
      loginWithRedirect({
        appState: { returnTo: getWindowUrl() },
      }),
    [loginWithRedirect]
  );
  return (
    <Root justifyContent="space-between" alignItems="center">
      <Flex.Item>
        <Flex alignItems="center">
          <Flex.Item>
            <Button styleAsLink onClick={() => history.push(HOME)}>
              <ImgWithFallback src={logoWebp} fallbackSrc={logoPng} width="311" height="31" />
            </Button>
          </Flex.Item>
          <NavigationMenuButton>
            <NavigationMenu />
          </NavigationMenuButton>
        </Flex>
      </Flex.Item>
      <Flex.Item>
        <LoginButton
          label={(isAuthenticated ? 'Logout' : 'Login').toUpperCase()}
          onClick={() => (isAuthenticated ? logout() : login())}
        />
      </Flex.Item>
    </Root>
  );
};

export default AppBar;
