// requires the matomo _paq object to be added to the global scope
// this is happening in index.html in a script

// to allow us to call window._paq.push, we need to teach typescript that it is a global variable
import Workflow from 'helpers/workflows';
import postFeedback from 'api/postFeedback';

declare global {
  interface Window {
    _paq: {
      // the input defines the action we want to push to the global object
      // usually the values in the array are strings, but sometimes it can also be a number
      push: (input: (string | number)[]) => void;
    };
  }
}

export const isMatomoAvailable = (): boolean => {
  const push = window?._paq?.push;
  return typeof push === 'function';
};

// this function is used to track page views using a specified path
// it is used in App.tsx to track each time the location changes
// we currently only use the path itself and all parameters are not forwarded!
export const trackPageView = (path: string): void => {
  if (!isMatomoAvailable()) {
    return;
  }
  window._paq.push(['deleteCustomVariables', 'page']);
  window._paq.push(['setCustomUrl', path]);
  window._paq.push(['trackPageView']);
};

// See Matomo guide for details on these parameters: https://matomo.org/docs/event-tracking/#the-anatomy-of-an-event
export const trackCustomEvent = (
  category: string,
  action: string,
  name?: string,
  value?: number
): void => {
  if (!isMatomoAvailable()) {
    return;
  }

  const eventDetails: (string | number)[] = ['trackEvent', category, action];
  if (name !== undefined) {
    eventDetails.push(name);
    value !== undefined && eventDetails.push(value);
  }
  window._paq.push(eventDetails);
};

// Tracking registration related events
// source is the URL the user came from when he/she clicked register
export const trackRegistrationStart = (source: string): void =>
  trackCustomEvent('registration', 'start', source);
export const trackRegistrationComplete = (): void => trackCustomEvent('registration', 'complete');

// Tracking Workflow related events
export const trackWorkflowOpened = (workflow: Workflow): void =>
  trackCustomEvent('workflow', 'open', workflow);
// The rating should be a numerical value, e.g. translating the smiley faces to values 1-5
export const trackWorkflowFeedback = (workflow: Workflow, score: number): void =>
  trackCustomEvent('workflow', 'userFeedback', workflow, score);

export const trackWorkflowClickInMenu = (workflow: Workflow): void =>
  trackCustomEvent('workflow', 'clickInActionsMenu', workflow);

export { postFeedback };
