import { Flex, Separator, styleVariables, Typography } from '@searchmetrics/core-ui-kit';
import React, { CSSProperties, FunctionComponent } from 'react';
import styled from 'styled-components';
import { useHistory } from 'react-router-dom';
import { getWindowUrl } from 'helpers/urlHelper';
import StandardTypography from 'styles/StandardTypography';
import StyledThemedButton from 'styles/StyledThemedButton';
import Workflow from 'helpers/workflows';
import workflowDetails from 'helpers/workflows/workflowDetails';
import Ribbon from 'styles/Ribbon';

const Root = styled(Flex)`
  position: relative;
  cursor: ${(props) => (props.onClick ? 'pointer' : 'default')};
  height: inherit;
`;

const Icon = styled.img`
  max-height: 60px;
  height: 60px;
  width: 100%;
  margin-bottom: 21px;
`;

const HeaderWrapper = styled(Flex.Item)`
  text-align: center;
  margin-bottom: ${styleVariables.spacings['space.11']};
`;

const InnerCardFlexItem = styled(Flex.Item)`
  margin-left: -${styleVariables.spacings['space.24']};
  margin-right: -${styleVariables.spacings['space.24']};
`;

const StyledSeparator = styled(Separator)`
  width: 100%;
  margin-bottom: ${styleVariables.spacings['space.24']};
`;

/**
 * we need to set height for description in order to align elements on all cards (shown in a row)
 * independently from the available text in each card description
 * this height is defined as: maxNumberOfLines(=3) * lineHeight(=1.5) * fontSize(=16)
 */
const HeadlineTypography = styled(StandardTypography)`
  height: calc(3 * 1.5 * ${styleVariables.typography['font.size.16']});
  font-size: ${styleVariables.typography['font.size.16']};
`;

const PositionedStyledThemedButton = styled(StyledThemedButton)`
  margin-top: ${styleVariables.spacings['space.24']};
`;

type Props = {
  workflow: Workflow;
  withSeparator?: boolean;
  withButton?: boolean;
  headerStyle?: CSSProperties;
  onClick?: () => void;
  isComingSoon?: boolean;
};

const WorkflowCard: FunctionComponent<Props> = ({
  workflow,
  withSeparator,
  withButton,
  headerStyle,
  onClick,
  isComingSoon,
}) => {
  const history = useHistory();
  const { name, headline, iconComponent, route } = workflowDetails[workflow];

  return (
    <Root
      direction="column"
      data-testid={`workflowCard-${workflow}`}
      {...(onClick ? { onClick } : {})}
    >
      {isComingSoon && <Ribbon />}
      <Flex.Item alignSelf="center">
        <Icon src={iconComponent} alt="" />
      </Flex.Item>

      <HeaderWrapper>
        <Typography fontSize="$font_size--20" fontWeight="$font_weight--bold" style={headerStyle}>
          {name}
        </Typography>
      </HeaderWrapper>
      {withSeparator && (
        <InnerCardFlexItem data-testid="content-separator">
          <StyledSeparator />
        </InnerCardFlexItem>
      )}
      <Flex.Item>
        <HeadlineTypography color="$purple_moderate--400">{headline}</HeadlineTypography>
      </Flex.Item>
      {withButton && (
        <Flex.Item>
          <PositionedStyledThemedButton
            data-testid="navigate-to-action-button"
            label="Start Action"
            onClick={() => history.push(getWindowUrl(route))}
            disabled={isComingSoon}
          />
        </Flex.Item>
      )}
    </Root>
  );
};
export default WorkflowCard;
