/**
 * # Setup
 *
 * Helpers to boostrap the application.
 */

/**
 *
 */
export function checkShouldUseRemoteModule(): boolean {
  const queryString = window.location.search;

  return queryString.includes('sm-discover-remote');
}

type Environment = 'staging' | 'production';

/**
 *
 */
export function getEnvironment(): Environment {
  const apiBaseUrl = process.env.REACT_APP_API_BASE_URL || '';
  const isStaging = apiBaseUrl.includes('staging');

  return isStaging ? 'staging' : 'production';
}
