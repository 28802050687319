import { pathOr } from 'ramda';
import { Location } from 'history';
import searchEngines from 'helpers/searchEngines';

export const parseUrlSearchParams = (location: Location): Record<string, string> => {
  const queryParams = new URLSearchParams(location.search);
  return {
    seId: queryParams.get('seId') || pathOr('', [0, 'se_desktop'], searchEngines),
    domain: queryParams.get('domain') || '',
    dateComparisonOption: queryParams.get('dateComparisonOption') || '',
  };
};

export const updateWindowUrl = (params: Record<string, string>, location: Location): void => {
  const newSearchParams = new URLSearchParams(params).toString();
  window.history.replaceState(null, '', `${location.pathname}?${newSearchParams.toString()}`);
};

export const getWindowUrl = (pathname?: string, search?: string): string =>
  `${pathname || window.location.pathname}${search || window.location.search}`;
