import styled from 'styled-components';
import { Button, styleVariables } from '@searchmetrics/core-ui-kit';
import React from 'react';
import { ThemedButtonProperties } from '@searchmetrics/core-ui-kit/components/General/Button/types';

const StyledButton = styled(Button.Themed)`
  padding: ${styleVariables.spacings['space.16']} ${styleVariables.spacings['space.24']};
  letter-spacing: 1.8px;
  text-transform: uppercase;
  :disabled {
    border-color: ${styleVariables.colors['green.moderate.cyan.900']};
    color: ${styleVariables.colors['green.moderate.cyan.900']};
    opacity: 0.3;
    filter: grayscale(100%);
  }
`;

const StyledThemedButton: React.ComponentType<ThemedButtonProperties> = ({
  label,
  onClick,
  fontSize = '$font_size--18',
  variant = 'outlined',
  disabled,
  className,
  ...rest
}) => (
  <StyledButton
    padding="medium"
    label={label}
    onClick={onClick}
    disabled={disabled}
    fontSize={fontSize}
    variant={variant}
    className={className}
    {...rest}
  />
);

export default StyledThemedButton;
