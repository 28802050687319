import * as React from 'react';
import { FunctionComponent } from 'react';
import styled from 'styled-components';
import { map } from 'ramda';
import { Icon, Typography, Flex, Button, Grid, styleVariables } from '@searchmetrics/core-ui-kit';
import { useHistory } from 'react-router-dom';
import { COMING_SOON } from 'routes';
import { getWindowUrl } from 'helpers/urlHelper';
import menuItems from 'components/NavigationMenu/menuItems';
import ComingSoonModal from 'components/NavigationMenu/ComingSoonModal';
import { trackWorkflowClickInMenu } from 'helpers/tracking';
import device, { APP_MAX_WIDTH } from 'helpers/device';
import { lightGreen, lightGrey, mediumGrey } from 'styles/colors';
import WorkflowCard from 'components/WorkflowCard';
import workflowDetails from 'helpers/workflows/workflowDetails';
import { useNavigationMenu } from './hooks';

const DropDown = styled.div`
  top: 75px;
  position: absolute;
  z-index: 100;
  background-color: white;
  box-shadow: inset 0 1px 0 0 ${mediumGrey};
  border: solid 1px ${mediumGrey};
  border-top: none;
  left: calc(10% + ${styleVariables.spacings['space.24']});
  right: calc(10% + ${styleVariables.spacings['space.24']});
  @media (${device.laptopL}) {
    max-width: ${APP_MAX_WIDTH};
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;
  }
`;

const ChevronIcon = styled(Icon)`
  font-size: ${styleVariables.typography['font.size.25']};
  margin-left: 2px;
  margin-top: 1px;
`;

const Sections = styled(Flex.Item)`
  border-right: solid 1px ${mediumGrey};
`;

interface SelectedSectionProps {
  selected: boolean;
}

const SectionItem = styled(Flex.Item)<SelectedSectionProps>`
  background-color: ${(props) => (props.selected ? lightGreen : styleVariables.colors.white)};
  padding: 36px;
  border-top: solid 1px ${mediumGrey};
  :hover {
    background-color: ${(props) => (props.selected ? lightGreen : lightGrey)};
  }
`;

const MenuItems = styled(Grid)`
  height: 100%;
  margin-left: ${styleVariables.spacings['space.50']};
  margin-right: ${styleVariables.spacings['space.50']};
`;

const NavigationMenu: FunctionComponent = () => {
  const history = useHistory();
  const {
    isActive,
    setIsActive,
    menuRef,
    selectedSectionKey,
    setSelectedSectionKey,
    selectedMenuItems,
    comingSoonWorkflow,
    setComingSoonWorkflow,
    onHide,
  } = useNavigationMenu();

  return (
    <div>
      <Button styleAsLink onClick={() => setIsActive(!isActive)}>
        <Flex alignItems="flex-start">
          <Typography fontSize="$font_size--20" color="$black">
            Actions
          </Typography>
          <ChevronIcon icon="$icon--chevron-down" color="$black" />
        </Flex>
      </Button>
      {isActive && (
        <DropDown ref={menuRef}>
          <Flex>
            <Sections>
              <Flex direction="column" justifyContent="center">
                {map(
                  ({ sectionKey }) => (
                    <Button
                      key={sectionKey}
                      styleAsLink
                      onClick={() => setSelectedSectionKey(sectionKey)}
                    >
                      <SectionItem key={sectionKey} selected={sectionKey === selectedSectionKey}>
                        <Typography
                          fontSize="$font_size--20"
                          fontWeight="$font_weight--bold"
                          color="$black"
                        >
                          {sectionKey}
                        </Typography>
                      </SectionItem>
                    </Button>
                  ),
                  menuItems
                )}
              </Flex>
            </Sections>
            <Flex.Item style={{ width: '100%' }}>
              <MenuItems
                columns={selectedMenuItems.length || 1}
                alignItems="center"
                justifyItems="center"
                gap={{ column: 50 }}
              >
                {selectedMenuItems.length ? (
                  map((workflow) => {
                    const { route } = workflowDetails[workflow];
                    return (
                      <Grid.Item key={workflow}>
                        <WorkflowCard
                          workflow={workflow}
                          onClick={() => {
                            if (route === COMING_SOON) {
                              trackWorkflowClickInMenu(workflow);
                              setComingSoonWorkflow(workflow);
                              return;
                            }
                            history.push(getWindowUrl(route));
                            setIsActive(false);
                          }}
                        />
                      </Grid.Item>
                    );
                  }, selectedMenuItems)
                ) : (
                  <Typography fontSize="$font_size--25">Coming Soon</Typography>
                )}
              </MenuItems>
            </Flex.Item>
          </Flex>
        </DropDown>
      )}
      {comingSoonWorkflow && <ComingSoonModal onHide={onHide} workflow={comingSoonWorkflow} />}
    </div>
  );
};
export default NavigationMenu;
