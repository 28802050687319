export const BACKLINK_OPPORTUNITIES = '/backlink-opportunities';
export const COMING_SOON = '/coming-soon';
export const CORE_WEB_VITALS = '/core-web-vitals';
export const HISTORIC_WINNERS_LOSERS = '/historic-winners-losers';
export const HOME = '/';
export const LOGIN = '/login';
export const OPTIMIZE_CONTENT = '/optimize-content';
export const PRIVACY_POLICY = '/privacy-policy';
export const RANKING_OPPORTUNITIES = '/ranking-opportunities';
export const TERMS_AND_CONDITIONS = '/terms-and-conditions';
