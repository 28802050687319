import { FunctionComponent } from 'react';

type Props = {
  src: string;
  fallbackSrc: string;
  type?: string;
  alt?: string;
  width: number | string;
  height: number | string;
};

/**
 * For more details on <picture/> see https://developer.mozilla.org/en-US/docs/Web/HTML/Element/picture
 */

const ImgWithFallback: FunctionComponent<Props> = ({
  src,
  fallbackSrc,
  type = 'image/webp',
  alt = '',
  ...delegated
}) => (
  <picture>
    <source srcSet={src} type={type} />
    <img src={fallbackSrc} alt={alt} {...delegated} />
  </picture>
);

export default ImgWithFallback;
