import { RefObject, useCallback, useEffect, useRef, useState } from 'react';
import { head, propOr, find, propEq } from 'ramda';

import Workflow from '../../helpers/workflows';
import menuItems from './menuItems';

interface UseNavigationMenuResult {
  isActive: boolean;
  setIsActive: (isActive: boolean) => void;
  menuRef: RefObject<HTMLDivElement> | null;
  selectedSectionKey: string;
  setSelectedSectionKey: (selectedSectionKey: string) => void;
  selectedMenuItems: string[];
  comingSoonWorkflow: Workflow | null;
  setComingSoonWorkflow: (comingSoonWorkflow: Workflow | null) => void;
  onHide: () => void;
}

export function useNavigationMenu(): UseNavigationMenuResult {
  const menuRef = useRef(null);
  const [isActive, setIsActive] = useState(false);
  const [comingSoonWorkflow, setComingSoonWorkflow] = useState<Workflow | null>(null);
  const [selectedSectionKey, setSelectedSectionKey] = useState<string>(
    propOr('', 'sectionKey', head(menuItems))
  );

  const onHide = useCallback(() => setComingSoonWorkflow(null), []);
  useEffect(() => {
    const pageClickEvent = (e) => {
      // If the active element exists and is clicked outside of
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      if (menuRef.current !== null && !menuRef.current.contains(e.target)) {
        setIsActive(!isActive);
      }
    };

    // If the item is active (ie open) then listen for clicks
    if (isActive) {
      window.addEventListener('click', pageClickEvent);
    }

    return () => {
      window.removeEventListener('click', pageClickEvent);
    };
  }, [isActive, menuRef]);

  const selectedMenuItems = propOr(
    [],
    'items',
    find(propEq('sectionKey', selectedSectionKey), menuItems)
  );

  return {
    isActive,
    setIsActive,
    menuRef,
    selectedSectionKey,
    setSelectedSectionKey,
    selectedMenuItems,
    comingSoonWorkflow,
    setComingSoonWorkflow,
    onHide,
  };
}
