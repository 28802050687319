import { FunctionComponent } from 'react';
import { Link } from 'react-router-dom';
import { Typography, styleVariables, Flex } from '@searchmetrics/core-ui-kit';
import { PRIVACY_POLICY, TERMS_AND_CONDITIONS } from 'routes';
import * as React from 'react';
import styled from 'styled-components';

const RootFlex = styled(Flex)`
  width: 100%;
  padding: 0 ${styleVariables.spacings['space.24']};
`;
const LinksFlexItem = styled(Flex.Item)`
  display: flex;
`;

const StyledLink = styled(Link)`
  text-decoration: none;
  :first-of-type {
    margin-left: ${styleVariables.spacings['space.06']};
  }
  color: ${styleVariables.colors['purple.moderate.400']};
  font-size: ${styleVariables.typography['font.size.16']};
  display: flex;
  align-items: center;
  :not(:first-of-type):before {
    content: '|';
    display: inline-block;
    margin: 0 ${styleVariables.spacings['space.06']};
  }
  :hover,
  :focus {
    color: ${styleVariables.colors['green.moderate.cyan.900']};
  }
`;

const AppFooter: FunctionComponent = () => {
  return (
    <RootFlex justifyContent="space-between" alignItems="center">
      <Flex.Item>
        <Typography color="$purple_moderate--400">{`© ${new Date().getFullYear()} Searchmetrics. All rights reserved.`}</Typography>
      </Flex.Item>
      <LinksFlexItem>
        <StyledLink to={PRIVACY_POLICY}>
          <Typography>Privacy Policy</Typography>
        </StyledLink>
        <StyledLink to={TERMS_AND_CONDITIONS}>Terms and Conditions</StyledLink>
      </LinksFlexItem>
    </RootFlex>
  );
};

export default AppFooter;
