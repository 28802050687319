import Workflow from 'helpers/workflows';

export type MenuSectionType = {
  sectionKey: string;
  items: Array<Workflow>;
};

const menuItems: Array<MenuSectionType> = [
  {
    sectionKey: 'Technical Optimization',
    items: [Workflow.CORE_WEB_VITALS, Workflow.STRUCTURED_DATA_CHECK],
  },
  {
    sectionKey: 'Content Optimization',
    items: [
      Workflow.OPTIMIZE_CONTENT,
      Workflow.RANKING_OPPORTUNITIES,
      Workflow.HISTORIC_WINNERS_LOSERS,
    ],
  },
  {
    sectionKey: 'Off-page optimization',
    items: [Workflow.BACKLINK_OPPORTUNITIES],
  },
];

export default menuItems;
